import { AccessibilityNew, AccountCircle, Audiotrack, Brightness4, Checkroom, Facebook, FormatQuote, Home, Instagram, LocationOn, Mail, MailOutlined, PhotoCamera, WhatsApp, Woman, YouTube } from '@mui/icons-material';
import { Avatar, Button, Grid, IconButton } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import React from 'react';
import "../styles/styles.scss";
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { url } from 'inspector';

export enum MainSelection {
  Originals = "originals",
}

interface BannerBackgroundImage {
  src: string,
  navbarColorInverted?: boolean,
}

interface ShowcaseQuote {
  name: string,
  title?: string,
  content: string,
  image: string,
}

const BannerBackgroundImages: {[key: string]: BannerBackgroundImage[]} = {
  "originals": [
    {
      src: "/images/banner/0S6A0039-comp.jpg",
    },

    {
      src: "/images/banner/0S6A0156-comp.jpg",
    },

    {
      src: "/images/banner/0S6A0076-comp.jpg",
    },

    // {
    //   src: "/images/banner/0S6A0156-comp.jpg",
    //   navbarColorInverted: true
    // },
  ], 

}

const ShowcaseQuotes: ShowcaseQuote[] = [
  {
    name: "Jenny Fong",
    title: "Ophthalmologist",
    content: "This is my very very first custom made wrap dress experience. It was so much fun, and most importantly, it fits so well.",
    image: "/images/quotes/quote-1-c.jpg"
  },
  {
    name: "Nicole Chan",
    title: "Wealth & Asset Management",
    content: "It just enhances my personality once I put the dress on. I just love how a wrap dress celebrating the different facets of a woman in a single wrap.",
    image: "/images/quotes/quote-2-c.jpg"
  }, 
  {
    name: "Winnie Poon",
    title: "Brand Owner, Yuan Jewelry",
    content: "There are over hundreds of prints & colors for me to choose! Thanks to the professional help from the stylists, I finally got the piece that was perfect for me.",
    image: "/images/quotes/quote-3-c.jpg"
  },
  {
    name: "Joey Sha",
    title: "Founder of First Footprint Education Group",
    content: "I travelled a lot. I just love the material of the wrap dress as it's wrinkle-free. It is just an ultimate do-it-all piece when it comes  to travel fashion.",
    image: "/images/quotes/quote-4-c.jpg"
  },
  { 
    name: "Fanny Wong",
    title: "District Manager",
    content: "Uncomplicated & easy-to-wear, despite the fashion trends, a wrap dress is one particular dress that's probably the most important in feminist history.",
    image: "/images/quotes/quote-5-c.jpg"
  },
  {    
    name: "Carina Tang", 
    title: "Chairman & Music Director",
    content: "Casual yet elegant. Swap them out for heels and a red lip to be ready for drinks that evening. Add a blazer and the look is immediately professional.",
    image: "/images/quotes/quote-6-c.jpg"
  }
]

interface OriginalItem {
  to: string;
  img: string;
  title: string;
  date: string;
}

interface YoutubeItem {
  src: string;
  description?: string;
}

export default class Main extends React.Component <{
  selection?: MainSelection,
  component?: JSX.Element,
}, {
  allLoaded: boolean,
  backgroundReload: boolean,
  bannerBackgroundStarted: boolean,
  bannerBackgroundId: number,
  bannerBackgroundPrevKey: string,
  bannerBackgroundInterval: number,
  bannerBackgroundReset: boolean,
}> {

  constructor(props) {
    super(props);
    this.state = {
      allLoaded: false,
      backgroundReload: false,
      bannerBackgroundStarted: false,
      bannerBackgroundId: 0,
      bannerBackgroundPrevKey: "",
      bannerBackgroundInterval: 0,
      bannerBackgroundReset: false,
    }
  }

  componentDidMount() {
    console.debug("componentDidMount");
    this.setState({
      allLoaded: true
    });
    this.backgroundReload();
    this.bannerBackgroundStart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selection != this.props.selection) {
      console.debug("Selection changed");
      this.backgroundReload();
      this.bannerBackgroundStart();
    }
  }

  backgroundReload() {
    if (this.state.bannerBackgroundInterval) {
      window.clearInterval(this.state.bannerBackgroundInterval);
    }
    
    this.setState({
      backgroundReload: false,
    }, () => {
      this.setState({
        backgroundReload: true
      });
    });
  }

  bannerBackgroundStart() {

    let startFn = () => {

      // Initial kickstart
      this.setState({
        bannerBackgroundReset: true,
        bannerBackgroundStarted: true,
        bannerBackgroundId: 0,
      }, () => {
        window.setTimeout(() => {
          this.setState({
            bannerBackgroundReset: false,
          });
        }, 10);
      })
      
      let id = window.setInterval(() => {

        // Prev id 
        // Banner background handling
        let activeBannerBackgroundSelection = this.props.selection || "originals";
        let activeBannerBackgrounds = BannerBackgroundImages[activeBannerBackgroundSelection];
        let activeBannerBackgroundId = activeBannerBackgrounds.length ? (this.state.bannerBackgroundId % activeBannerBackgrounds.length) : 0;
        // let activeBannerBackground = activeBannerBackgrounds[activeBannerBackgroundId];
        let activeBannerBackgroundPrevKey = activeBannerBackgroundSelection + "-" + activeBannerBackgroundId;

        this.setState({
          bannerBackgroundReset: true,
          bannerBackgroundPrevKey: activeBannerBackgroundPrevKey,
          bannerBackgroundId: this.state.bannerBackgroundId + 1,
        }, () => {
          window.setTimeout(() => {
            this.setState({
              bannerBackgroundReset: false,
            });
          }, 30);
        })

        
        
      }, 10000);
      this.setState({
        bannerBackgroundInterval: id
      });
    }

    if (this.state.bannerBackgroundStarted) {
      // End the previous interval first
      if (this.state.bannerBackgroundInterval) {
        window.clearInterval(this.state.bannerBackgroundInterval);
      }
      this.setState({
        bannerBackgroundStarted: false,
        bannerBackgroundId: 0,
      }, () => {
        startFn();
      })
    } else {
      startFn();
    }
  }

  render() {


    // Banner background handling
    let activeBannerBackgroundSelection = this.props.selection || "originals";
    let activeBannerBackgrounds = BannerBackgroundImages[activeBannerBackgroundSelection];
    let activeBannerBackgroundId = activeBannerBackgrounds.length ? (this.state.bannerBackgroundId % activeBannerBackgrounds.length) : 0;
    let activeBannerBackground = activeBannerBackgrounds[activeBannerBackgroundId];
    let activeBannerBackgroundKey = activeBannerBackgroundSelection + "-" + activeBannerBackgroundId;
    let navbarColorInverted = activeBannerBackground.navbarColorInverted;

    let bannerBackgroundDom = (
      <React.Fragment>
        {
          Object.keys(BannerBackgroundImages).map((selection) => {
            return BannerBackgroundImages[selection].map((item, index) => {
              let key = selection + "-" + index;
              return (
                <div 
                  className="banner-background"
                  style={{
                      backgroundImage: `url('${process.env.PUBLIC_URL}${item.src}')`
                    }}
                  key={key}
                  data-key={key}
                  data-active={
                    this.state.bannerBackgroundStarted 
                    && (activeBannerBackgroundKey == key)
                  }
                  data-prev-active={
                    this.state.bannerBackgroundPrevKey == key
                  }
                  data-reset={
                    this.state.bannerBackgroundStarted 
                    && (activeBannerBackgroundKey == key)
                    && this.state.bannerBackgroundReset
                  }
                >
                </div>
              )
            })
          })
          
        }
      </React.Fragment>
    )

    return (
      <div className="page-main">

        <div
          className="top-navbar"
        >
          <div className="left">
            <img className="logo" src="/images/wrap-me-love-logo-text-md.png" />
          </div>

          <div className="right">
            <a href="https://wa.me/85268021333" target="_blank">
              <IconButton 
                className="icon-button" 
                aria-label="whatsapp" 
                component="span" 
                size="large"
              >
              
                <WhatsApp className="icon" />
              </IconButton>
            </a>
            <a href="https://www.facebook.com/wrapmelove" target="_blank">
              <IconButton 
                className="icon-button" 
                aria-label="facebook" 
                component="span" 
                size="large"
              >
              
                <Facebook className="icon" />
              </IconButton>
            </a>
            <a href="https://www.instagram.com/wrapmelove" target="_blank">
              <IconButton 
                className="icon-button" 
                aria-label="instagram" 
                component="span" 
                size="large"
              >
              
                <Instagram className="icon" />
              </IconButton>
            </a>
          </div>

        </div>

        <div 
          className="banner"
        >
          {/* <Fade when={this.state.backgroundReload}>
            <div 
            className="banner-background"
            style={{
                backgroundImage: `url('${process.env.PUBLIC_URL}${backgroundImg}')`
              }}
            >
            </div>
          </Fade> */}

          {
            bannerBackgroundDom
          }
          
          
          <Fade cascade duration={2000}>
            <div className="logo">
              <img src="/images/wrap-me-love-logo-no-text.png" />
            </div>
          </Fade>
          <Fade cascade duration={2000}>

            <div className="navbar">
              <Link to="/" >
                <IconButton 
                  className="icon-button" 
                  aria-label="home" 
                  component="span" 
                  size="large"
                  data-inverted={navbarColorInverted}
                >
                  <Home className="icon" />
                </IconButton>
              </Link>

              <a href="https://www.instagram.com/wrapmelove" target="_blank">
                <IconButton 
                  className="icon-button" 
                  aria-label="instagram" 
                  component="span" 
                  size="large"
                  data-inverted={navbarColorInverted}
                >
                
                  <Instagram className="icon" />
                </IconButton>
              </a>
            
            </div>
          
          </Fade>
          <Fade cascade top delay={800} duration={2000}>
            {/* <div className="slogan">
              A dress to speak to yourself
            </div> */}
          </Fade>
        </div>

        
        
        {
          this.props.selection && (
            <div className="main-items">

              <Fade cascade delay={100} duration={2000}>
                <div 
                  className="main-item promo-slogans" 
                  style={{
                    marginBottom: 0,
                    backgroundImage: `url("/images/0S6A9865-white.jpg")`
                  }}
                >
              
                  <div className="promo-slogan title">
                    Wrap Me Love...
                  </div>

                  <div className="promo-slogan">
                   <Checkroom /><br />A dress to speak to yourself
                  </div>

                  <div className="promo-slogan">
                    <AccessibilityNew /><br /> A dress showing your confidence
                  </div>

                  <div className="promo-slogan">
                    <Brightness4 /><br /> A dress you can wear from day to night 
                  </div>

                  <div className="promo-slogan">
                    <AccountCircle /><br /> A pattern to express your personality 
                  </div>
                
                
                </div>
              </Fade>

              <div 
                className="main-item pattern-lines" 
                style={{
                  marginTop: 0,
                  marginBottom: "-35px",
                  //backgroundImage: `url("/images/0S6A9865-white.jpg")`
                }}
              >
                <Masonry columns={{ xs: 1, sm: 2, md: 2 }} spacing={2}>
                  <div className="item img">
                    <img src="/images/circle-1.png" />
                  </div>

                  <div className="item msg">
                    &nbsp;<br />
                    <div className="title">
                      MADE TO FIT
                    </div>
                    <div className="content">
                      Wrap Me Love offering customisable wrap dresses that bring style and sophistication to any look you assemble. From long wrap dresses that radiate regal to midi, ankle or casual maxi styles that bring a touch of whimsy to your wardrobe. Can't decide on your own? Our experieced stylists will make suggestions for you according to your body type while doing  the measurments.
                    </div>
                  </div>

                  <div className="item msg">
                    <div className="title">
                      Wrap dress: The wardrobe staple
                    </div>
                    <div className="content">
                      There's something about wrap dresses that sets them apart from other dress genres. 
                      These waist-cinching wardrobe staples are the ultimate blend of classy and comfortable. 
                      The dress wraps gently around the body, accentuates the waist and bust while creating curves to flatter the overall body shape. 
                      Elegant, gracious and feminine, our designs are made to complement your style, body and lifestyle.
                      <br />&nbsp;
                    </div>
                  </div>

                  <div className="item img">
                    <img src="/images/circle-2.png" />
                  </div>
                </Masonry>
                
              </div>

              {/* <div className="main-item">
              
                
                <div className="texture">
                  <img src="/images/texture-1.jpg" />
                </div>
                <div className="texture">
                  <img src="/images/texture-2.jpg" />
                </div>
                <div className="texture">
                  <img src="/images/texture-3.jpg" />
                </div>
              </div> */}


              {/* <div className="main-item">
                <iframe
                  className="main-item-player"
                  src="https://www.youtube.com/embed/K1QICrgxTjA"
                  title="YouTube video player" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen>
                </iframe>    
              </div> */}

              <div 
                className="main-item promo-video-item"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/images/promo-video-screenshot-blurred.jpg')` 
                }}
              >
                <div className="promo-video">
                  <video
                    id="my-player"
                    className="video-js vjs-theme-fantasy"
                    controls
                    preload="auto"
                    poster="/images/promo-video-screenshot.jpg"
                    data-setup='{"fluid": true, "aspectRatio": "16:9"}'>
                    <source src="/promo.mp4" type="video/mp4"></source>
                    <p className="vjs-no-js">
                      To view this video please enable JavaScript, and consider upgrading to a
                      web browser that
                      <a href="https://videojs.com/html5-video-support/" target="_blank">
                        supports HTML5 video
                      </a>
                    </p>
                  </video>
                </div>
              </div>

              <div className="main-item">
                <div className="slide-container">
                  <Slide
                    duration={8000}
                    transitionDuration={1300}
                    arrows={true}
                    indicators={true}
                    easing="ease"
                  >
                  {
                    ShowcaseQuotes.map((quote, index) => {

                      return (
                        <div className="each-slide">
                          <div className="showcase">
                          <Grid 
                            container
                            spacing={2} 
                            direction={(index % 2 == 0) ? "row" : "row-reverse"}
                          >
                            <Grid item xs={12} sm={5} md={4}>
                              <div className="showcase-img">
                                <img src={quote.image} />
                              </div>
                            </Grid>
                            <Grid 
                              container item xs={12} sm={7} md={8}
                              alignItems="center"
                              justifyContent="center"
    
                              direction="column"
                            >
                              <div className="showcase-quote-icon"><FormatQuote /></div>
                              <div className="showcase-quote">{quote.content}</div>
                              <div className="showcase-name">{quote.name}</div>
                              {
                                quote.title && (
                                  <div className="showcase-title">{quote.title}</div>
                                )
                              }
                            </Grid>
                          </Grid>
                            
                          </div>
                        </div>
                      )
                    })
                  }

                  </Slide>
                </div>
              </div>

              <div className="main-item hr-top" style={{paddingTop: "0.9em"}}>
              
                <h2>
                  Contact us
                </h2>

                <p className="center">
                  <Facebook /> Facebook: <a href="https://www.facebook.com/wrapmelove" target="_blank">@wrapmelove</a>
                </p>
                <p className="center">
                  <Instagram /> Instagram: <a href="https://www.instagram.com/wrapmelove" target="_blank">@wrapmelove</a>
                </p>
                <p className="center">
                  <WhatsApp /> Whatsapp: <a href="https://wa.me/85268021333" target="_blank">+852-68021333</a>
                </p>
                <p className="center">
                  <MailOutlined /> Email: <a href="mailto:contact@wrapmelove.com" target="_blank">contact@wrapmelove.com</a>
                </p>
                <p className="center">
                  <LocationOn /> Address: 
                  <a href="https://maps.app.goo.gl/3VviHJFRFYSCnhQL6" target="_blank">
                    Unit 906-7, 9/F Hing Yip Commercial Centre, 272-284 Des Voeux Road Central, Sheung Wan, Hong Kong
                  </a>
                </p>

                {/* <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe width="613" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=Unit%201201,%2012/F%20Cheungs%20Building,%201-3%20Wing%20Lok%20Street,%20Sheung%20Wan%20Hong%20Kong&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}>
                    </iframe>
                    <a href="https://123movies-to.org">
                    </a>
                    <br />
                  </div>
                </div> */}

              </div>
            </div>    
          )
        }
        <div className="footer hr-top">
          <div>

            <a href="https://www.instagram.com/wrapmelove" target="_blank">
              <IconButton aria-label="Instagram">
                <Avatar style={{backgroundColor: "#bc2a8d"}}>
                  <Instagram />
                </Avatar>
              </IconButton>
            </a>

            <a href="https://www.facebook.com/wrapmelove" target="_blank">
              <IconButton aria-label="Facebook">
                <Avatar style={{backgroundColor: "#4267B2"}}>
                  <Facebook />
                </Avatar>
              </IconButton>
            </a>
            
          </div>
          <p>
            Copyright © 2025 Wrap Me Love. All rights reserved.
          </p>
        </div>
      </div>
    )
  }
}